import * as React from "react";

import Logo from "../images/logo.png";

// markup
const IndexPage = () => {

	return (
		<div className="mx-4">
			<title>Almalu Products USA, LLC</title>
			<div className="flex flex-col mx-auto items-center justify-center my-5">
				<img src={Logo} alt="Almalu Natural Logo" className="w-1/2 lg:w-1/4 h-auto" />
				<h1 className="font-bold text-xl md:text-4xl text-navy-700 mt-5 text-center max-w-xl">Natural supplements made in USA</h1>
				<a href="https://almalunatural.com" className="rounded-3xl shadow bg-gold-400 hover:bg-gold-500 transition-colors w-32 md:w-48 text-center py-4 px-6 text-white mt-5">Visit store</a>
				<h1>NODE ENV {process.env.NODE_ENV} | GTM ID: {process.env.GATSBY_GTM_ID} | SITE: {process.env.GATSBY_SITE}</h1>
			</div>
		</div>
	);
};

export default IndexPage;
